import React, { useEffect, useState } from "react";
import useInterval from './useInterval';
 
const NODEV = "";
const NOUID = "";
const NODATA = [];

const App = () => {
  const [port, setPort] = useState(null);
  const [writer, setWriter] = useState(null);
  const [reader, setReader] = useState(null);

  const [info, setInfo] = useState(NODEV);
  //const [present, setPresent] = useState(false);
  const [uid, setUID] = useState("");
  const [data, setData] = useState([]);
  const [poll, setPoll] = useState(false);

  const connectPort = async (force) => {
    let newPort;
    if (force) {
      try {
        newPort = await navigator.serial.requestPort();
        setPort(newPort);
      } catch (error) {
        //setPresent(false);
        setPort(null);
        console.error("There was an error opening the port: ", error);
        return
      }
    }
    if ('serial' in navigator) {
      const ports = await navigator.serial.getPorts();
      if (ports.length === 0) {
       
        try {
          newPort = await navigator.serial.requestPort();
        } catch (error) {
          //setPresent(false);
          setPort(null);
          console.error("There was an error opening the port: ", error);
          return
        }
        setPort(newPort);
      } else {
        setPort(ports[0]);
      }
    }
  };

  const closePort = async () => {
    setPort(null);
    try {
      //reader.cancel();
      //writer.close();

      reader.releaseLock();
      writer.releaseLock();
    } catch (error) {
      console.error("There was an error closing the port: ", error);
    }
    if (port) {
      try {
        await port.close();
      } catch (error) {
        console.error("There was an error closing the port: ", error);
      }
   
    }
    setUID(NOUID);
  };

  const sendMessage = async (message) => {
    if (port && writer) {
      const data = new TextEncoder().encode(message+"\r\n");
      //console.log("send: "+message+"\r\n");
      try {
        await writer.write(data);
      } catch (error) {
        //await closePort();
        console.error("There was an error writing data: ", error);
      }
     
    }
  };

  const readLoop = async () => {
    try {
      while (reader) {
        const { value, done } = await reader.read();

        if (value) {
          const data = new TextDecoder().decode(value);
          //console.log(data);
      
          if (!data.startsWith('\r\n') || !data.endsWith('\r\n')) { 
            console.log("skip broken packet");
          } else if (data.startsWith('\r\nOpen Development RFID Reader')) {
            const s = data.slice(2, data.length-6);  
            setInfo(s);
            //setPresent(true);
          } else if (data.startsWith('\r\n+UID=') && data.endsWith('OK\r\n')) {
            setUID(data.slice(7, data.length-6));
          } 
          else if (data.startsWith('\r\n+DATA ') && data.endsWith('OK\r\n')) {
            setData(data.slice(7, data.length-6));
            console.log("data: "+data.slice(7, data.length-6));
          }
          else {
            setUID("");
            setData(NODATA);
          }
        }
        if (done) {
          await closePort();
          break;
        }
      }
    } catch (error) {
      await closePort();
    }
  };

   useEffect(() => {
    if ((port) && (reader)) {
      sendMessage("ATI");
      setTimeout(() => {sendMessage("AT+SCAN0"); setPoll(true);} , 200);
      readLoop();
    }
  }, [port, reader]);

  useEffect(() => {
      connectPort(false);
  }, []);

  useInterval(() => {
    if ((port) && (reader) && (poll)) {
      sendMessage("AT+i");
      //setTimeout(() => {sendMessage("AT+R0");}, 200);
    }
  }, 400);

  useEffect(() => {
    if (port) {
      (async () => {
        try {
          await port.open({ baudRate: 9600 });
         
        } catch (error) {
          return;
        }
        const writer = port.writable.getWriter();
        const reader = port.readable.getReader();
        setWriter(writer);
        setReader(reader);
      })();
    }
  }, [port]);

  return ( <div className="bg-repeat bg-base-200 p-6 w-full h-screen" style={{backgroundImage: "radial-gradient(hsla(var(--bc) /.2) .5px, hsla(var(--b2) /1) .5px)", backgroundSize: "5px 5px"}}>
            <div className="card w-full bg-base-100 h-12 rounded-xl shadow-xl flex flex-row items-center">
                  <p className="ml-8">{info}</p>        

                  <div className="grow"/>
                  
                  {false &&
                  <button className="mr-4 btn btn-accent btn-outline btn-sm" onClick={()=>{setUID(NOUID);}}>
                    &nbsp;&nbsp;&nbsp;Clear&nbsp;&nbsp;&nbsp;
                  </button>
                  }

                  {port?
                  <button className="mr-2 btn btn-accent btn-outline btn-sm" onClick={closePort}>
                    Disconnect
                  </button>
                  :
                  <button className="mr-2 btn btn-accent btn-outline btn-sm" onClick={()=>connectPort(true)}>
                    Connect
                  </button>
                  }
              </div>
    
              <div className="w-full bg-base-100 rounded-xl shadow-xl mt-4 p-4 flex flex-row  items-center text-center justify-center">
              <div className={"space-x-4 flex flex-row"}>
                    UID: [{uid}]
                  </div>
              </div>
            </div>

  );
};

export default App;